import React, { Component } from 'react';
import Phone from './../img/call_black_24dp.svg';
class ConnectionPage extends Component {

    constructor(props) {
        super(props);
        this.state = {name: '', email: '', message: '', success: false};
    }

    onNameChange = function (e) {
        this.setState({name: e.target.value});
    }
    onEmailChange = function (e) {
        this.setState({email: e.target.value});
    }
    onMessageChange = function (e) {
        this.setState({message: e.target.value});
    }

    onSendMessage = function () {
        (async () => {
            const rawResponse = await fetch('/homepageBack/mail.php', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state)
            });
            const content = await rawResponse.json();
            this.setState(content);

        })();
    }

    validateName = function () {
        if (typeof this.state.name === 'undefined') {
            return false;
        }
        if (this.state.name === null) {
            return false;
        }
        if (this.state.name.length === 0) {
            return null;
        }

        return true;
    }

    sendMessage = function () {

    }

    validateEmail = function () {
        var pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (pattern.test(this.state.email)) {
            return true;
        }
        return false;
    }

    validateMessage = function () {

        if (typeof this.state.message === 'undefined') {
            return false;
        }

        if (this.state.message === null) {
            return false;
        }

        if (this.state.message.length === 0) {
            return null;
        }

        return true;
    }

    validateForm = function () {

        var nameOK = this.validateName();
        var emailOK = this.validateEmail();
        var messageOK = this.validateMessage();

        if (nameOK && emailOK && messageOK) {
            return true;
        }

        return false;
    }

    render() {

        var fromOK = this.validateForm();


        var contactMessage = "";
        if (this.state.success) {
            contactMessage = <div className="card bg-light mb-3">
                <div className="card-header bg-success text-white text-uppercase"><i className="fa fa-home"></i>Kapcsolattartás</div>
                <div className="card-body">
                    <p>Az Email kézhezvételétől a lehető legrövidebb idő allatt, igyekszem felvenni a kapcsolatot az érdeklődőkkel.</p>
                    <p>Üdvözlettel: Demkó Róbert</p>
                </div>
            </div>;
        }

        return (<div style={{width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'center'}} >
            <div >
                <div >
                    &nbsp;
                </div>
                <p className="lead my-12 text-center">Amennyiben további kérdése lenne vagy megbízást szeretne adni, az alábbi telefonszámon vagy űrlap kitöltésével tud kapcsolatba lépni velem.</p>
                  <img  style={{maxHeight:25}} src={Phone} /> 
                  <a style={{textDecoration:'none',cursor:'pointer',color:'black',fontSize:16}} href="tel:+36305481390">&nbsp;&nbsp;Tel.:&nbsp;+36 (30) 548-1390&nbsp;&nbsp;&nbsp;&nbsp;</a>
                <h3 className="pb-3 mb-4 font-italic border-bottom" />
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div style={{backgroundColor: '#346a99'}} className="card-header  text-white  text-center"><i className="fa fa-envelope"></i> 
                                Kapcsolattartási űrlap. 
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="name">Az Ön neve</label>
                                        <input onChange={this.onNameChange.bind(this)} value={this.state.name} type="text" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Írja be a nevét" required/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Az Ön Email címe</label>
                                        <input onChange={this.onEmailChange.bind(this)} value={this.state.email} type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Írja be az Email címét" required/>
                                        <small id="emailHelp" className="form-text text-muted"></small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Üzenet szövege</label>
                                        <textarea onChange={this.onMessageChange.bind(this)} value={this.state.message} className="form-control" id="message" rows="6" required></textarea>
                                    </div>
                                    <div className="mx-auto  text-center">
                                        <br/>
                                        <button     
                                            disabled={fromOK === false}  
                                            onClick={this.onSendMessage.bind(this)}   
                                            type="button" 
                                            style={{backgroundColor: '#346a99',color:'white'}} 
                                            className="btn text-right">
                                            Üzenet küldése
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4  text-center">
                        {contactMessage}
                    </div>
                </div>        
            </div>  
            <h3 className="pb-3 mb-4 font-italic border-bottom" />
            
        </div>);
    }
}

export default ConnectionPage;