import React from 'react';
import {Container, Nav, Navbar, NavDropdown, Row, Col} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Experience from './home/Experience';
import WideCard from './components/WideCard';
import Widget from './components/Widget';


import Husz from './img/husz.svg';
import ImgTime from './img/schedule_white_24dp.svg';
import ImgConnection from './img/cable_white_24dp.svg';
import ImgCommunicaton from './img/connect_without_contact_white_24dp.svg';
import ImgDocs from './img/plagiarism_white_24dp.svg';
import ImgIntegration from './img/add_to_drive_white_24dp.svg';
import ImgCalendar from './img/calendar_month_white_24dp.svg';
import ImgDashboard from './img/line_axis_white_24dp.svg';
import ImgProblems from './img/computer_white_24dp.svg';

import companyBackgorund from './img/company_background_transparent.jpg';




class HomePage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        // style={{ backgroundImage: `url(${office})` }}
        return (<div>
        
            <Row>       
                <Col style={{display: 'flex', justifyContent: 'center', textAlign: 'left'}}  sm="12" >
                <Experience />
                </Col>
            </Row>
            <Row>       
                <Col style={{display: 'flex', justifyContent: 'center', textAlign: 'center', margin: 20, paddingTop: 10, borderBottom: 'dashed 1px lightgrey'}}  sm="12" >
                </Col>
            </Row>
            <Row style={{
                            backgroundImage: `url(${companyBackgorund})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                             }}>       
                <Col sm="12" md="6"  lg="4" xl="3">
                <Widget icon={ImgTime} content={"Az adminisztrációs folyamatok lerövidítése által pénzt és időt megtakarítani ügyfeleinknek."} />
                </Col>
                <Col sm="12" md="6"  lg="4" xl="3">
                <Widget icon={ImgConnection} content={"Összekötni a cégeket az ügyfelekkel vagy más vállalkozásokkal. "} />
                </Col>
                <Col sm="12" md="6"  lg="4" xl="3">
                <Widget icon={ImgDocs} content={"Rendszerezni, megőrizni és kereshetővé tenni a dokumentumokat és más fontos információkat. "} />
                </Col>
                <Col sm="12" md="6"  lg="4" xl="3">
                <Widget icon={ImgCommunicaton} content={"Cégen belüli értesítési rendszert és kommunikációs csatornát kialakítani."} />
                </Col>
                <Col sm="12" md="6"  lg="4" xl="3">
                <Widget icon={ImgIntegration} content={"Szolgáltatásokat integrálni a publikus interfésszel is rendelkező online felületekről. Pl.: (Céginformációs rendszerek, NAV, online számlázók, Google szolgáltatások.)"} />
                </Col>
                <Col sm="12" md="6"  lg="4" xl="3">
                <Widget icon={ImgCalendar} content={"Naptár alapú események határidők, szabadságok, munkabeosztások, munkafolyamatok intelligens támogatását."} />
                </Col>
                <Col sm="12" md="6"  lg="4" xl="3">
                <Widget icon={ImgDashboard} content={"A rendszerből kinyerhető információk átlátható prezentálását a döntési folyamatok elősegítése érdekében."} />
                </Col>
                <Col sm="12" md="6"  lg="4" xl="3">
                <Widget icon={ImgProblems} content={"Könnyebb és élhetőbb munkakörnyezetet teremteni vállalkozása számára. "} />
                </Col>
                <Col sm="12" md="6"  lg="4" xl="3"></Col>
                <Col sm="12" md="6"  lg="4" xl="3"></Col>
                <Col sm="12" md="6"  lg="4" xl="3"></Col>
                <Col sm="12" md="6"  lg="4" xl="3"></Col>
        
            </Row>
        </div>
                );
    }
}

export default HomePage;
