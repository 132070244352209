import React from 'react';
import {Container, Nav, Navbar, NavDropdown, Row, Col} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


import img2 from './img/development_2k.jpg';
import img3 from './img/development_3.jpg';

class DevelopmentPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return ([

            <Row style={{display: 'flex', textAlign: 'center', justifyContent: 'center'}} key="waste">       
                <div style={{maxWidth: 800, textAlign: 'left'}}>
                    <br/>
                    <h2>Miért éri meg automatizálni a vállalati folyamatokat?</h2>
            
            
            
                    <p>A technológia és a munka világának fejlődése szorosan összefügg az automatizálással. Az emberiség mindig arra törekedett, hogy egyszerűsítse és hatékonyabbá tegye a munkát. A munkafolyamatok automatizálása mára alapvető fontosságúvá vált, hiszen ez teszi lehetővé a vállalatok számára, hogy gyorsabban és hatékonyabban működjenek, miközben minimalizálják az emberi hibákat.</p>
                    <br/>
                    <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center'}}><img  style={{maxHeight: 400}} src={img2}/></div>
                    <br/>
            
                    <h3>Történelmi áttekintés az automatizálás szerepéről</h3>
            
                    <p>A 20. század elején az Egyesült Államok lakosságának körülbelül <strong>40%-a</strong> dolgozott a mezőgazdaságban. Ez ma kevesebb, mint <strong>2%</strong>. Miért? Az automatizáció átalakította a mezőgazdasági ipart. Gépekkel, modern technológiákkal és hatékonyabb munkafolyamatokkal elérték, hogy kevesebb ember is sokkal több élelmiszert termeljen, ami alapvetően megváltoztatta a gazdaságot és a társadalmat.</p>
            
                    <p>Azok a vállalatok, amelyek nem reagáltak időben az automatizáció adta lehetőségekre, elbuktak. Gondoljunk csak a nagy ipari cégekre, melyek nem léptek időben az automatizáció irányába, és emiatt elvesztették a versenyelőnyüket, sőt, néha a piacot is. Ugyanakkor azok a vállalatok, amelyek befektettek az automatizált rendszerekbe, mint például a Ford, amely bevezette a gyártósorok automatizálását, soha nem látott sikereket értek el.</p>
                    <br/>
                    <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center'}}><img  style={{maxHeight: 400}} src={img3}/></div>
                    <br/>
                    <h3>A tevékenységek automatizálásának jelentősége ma</h3>
            
                    <p>Ma az automatizálás többé nem egy lehetőség – hanem szükségszerűség. Az egyedi ERP rendszerek pontosan azt a célt szolgálják, hogy a cég működését optimalizálják, a folyamataikat gyorsabbá, átláthatóbbá és hatékonyabbá tegyék. Egy jól kialakított ERP rendszer bevezetése időt és pénzt spórol, csökkenti az adminisztrációs terheket, és lehetővé teszi a vezetőség számára, hogy az erőforrásaikat a stratégiai döntésekre összpontosítsák.</p>
            
                    <p><strong>A kérdés nem az, hogy megéri-e automatizálni – hanem az, hogy mennyi időt és pénzt veszít egy vállalat, ha nem teszi meg.</strong></p>
                </div>
            </Row>

        ]);
    }
}

export default DevelopmentPage;
