import React from 'react';
import {Container, Nav, Navbar, NavDropdown, Row, Col} from 'react-bootstrap'
import HomePage from './HomePage';
import DevelopmentPage from './DevelopmentPage';
import TechnologiesPage from './TechnologiesPage';
import ConnectionPage from './connection/ConnectionPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Logo from './img/mini_logo_light.svg';
import office from './img/office.jpg';
import Phone from './img/call_black_24dp.svg';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activePageIndex: 0,
            navExpanded: false
        }
        this.pages = ["HomePage", "DevelopmentPage", "TechnologiesPage", "ConnectionPage", "GDPR"];
    }

    pageSwitcher = function (e) {
        window.scrollTo(0, 0);
        this.setState({activePageIndex: e.target.id});
        this.closeNav();
    }

    setNavExpanded = function () {
        window.scrollTo(0, 0);
        this.setState({navExpanded: !this.state.expanded});
    }

    closeNav = function () {
        window.scrollTo(0, 0);
        this.setState({navExpanded: false});
    }

    prevPage = function () {
        window.scrollTo(0, 0);
        if (this.state.activePageIndex == "0" || this.state.activePageIndex == "4") {
            this.setState({activePageIndex: "3"});
        } else {
            this.setState({activePageIndex: (this.state.activePageIndex * 1) - 1 + ""});
        }
    }
    nextPage = function () {
        window.scrollTo(0, 0);
        if (this.state.activePageIndex == "3" || this.state.activePageIndex == "4") {
            this.setState({activePageIndex: "0"});
        } else {
            this.setState({activePageIndex: (this.state.activePageIndex * 1) + 1 + ""});
        }
    }

    render() {

        var activeContent = null;
        var title = "";
        if (this.state.activePageIndex == "0") {
            activeContent = <HomePage />
            title = "EGYEDI SZOFTVEREK FEJLESZTÉSE";
        }
        if (this.state.activePageIndex == "1") {
            activeContent = <DevelopmentPage />
            title = "HATÉKONYSÁG MEGTÉRÜLÉS";
        }
        if (this.state.activePageIndex == "2") {
            activeContent = <TechnologiesPage />
            title = "TECHNOLÓGIÁK";
        }
        if (this.state.activePageIndex == "3") {
            title = "Kapcsolat";
            activeContent = <ConnectionPage />
        }
        if (this.state.activePageIndex == "4") {
            title = "GDPR";
            activeContent = <p>Nem tárolunk adatokat az oldal látogatóiról.</p>
        }


        return (
                <div className="App">
                    <div style={{position: 'fixed', top: 0, zIndex: 100, minWidth: '100%', backgroundColor: 'white', borderBottom: 'solid 1px lightgray'}}>
                        <div style={{
                            display:'block',
                            width:'98%',
                            textAlign:'right',
                            fontSize:'0.8em',
                            fontFamily:'rasha',
                            textShadow: '1px 1px #00000044'
                            
                        }}>
                           <img  style={{maxHeight:25}} src={Phone} /> 
                           <a style={{textDecoration:'none',cursor:'pointer',color:'black',fontSize:12}} href="tel:+36305481390">&nbsp;&nbsp;Tel.:&nbsp;+36 (30) 548-1390&nbsp;&nbsp;&nbsp;&nbsp;</a>
                        </div>
                        <Navbar 
                            bg="light" 
                            expand="lg"
                            onToggle={this.setNavExpanded.bind(this)}
                            expanded={this.state.navExpanded}
                            >
                            <Container>
                                <Navbar.Brand >
                                    <div style={{display: 'flex', textAlign: 'center', backgroundColor: 'white'}}>
                                        <img src={Logo}/>&nbsp;&nbsp;
                                        <div style={{backgroundColor: '#fa653d', color: 'white', padding: 5}} >ERP</div>
                                        <div style={{backgroundColor: 'white', color: 'black', padding: 5}}>Link</div>
                                    </div>
                                </Navbar.Brand>
                                <Navbar.Toggle  aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto" style={{fontFamily:'rasha', fontWeight:'bold',textShadow:'1px 1px 6px #00000055'}}>
                                        <Nav.Link id="0" onClick={this.pageSwitcher.bind(this)}>FŐOLDAL</Nav.Link>
                                        <Nav.Link id="1" onClick={this.pageSwitcher.bind(this)}>MEGTÉRÜLÉS</Nav.Link>
                                        <Nav.Link id="2" onClick={this.pageSwitcher.bind(this)}>TECHNOLÓGIÁK</Nav.Link>
                                        <Nav.Link id="3" onClick={this.pageSwitcher.bind(this)}>KAPCSOLAT</Nav.Link>
                                        <Nav.Link id="4" style={{fontStyle: 'italic'}} onClick={this.pageSwitcher.bind(this)}>GDPR</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        <Row style={{backgroundColor: '#d6e3e3', display: 'flex', margin: 0, borderTop: 'solid 1px #fa653d99', borderBottom: 'solid 4px #346a9966'}}>       
                            <Col sm={12} style={{display: 'flex'}}>
                            <img  src={office} style={{maxWidth: 150, opacity: 0.7, boxShadow: '0px 0px 8px black', borderRadius: 8}} />
                            <div
                                style={{
                                        marginTop: 30,
                                        marginLeft: 20,

                                        justifyContent: 'Left',
                                        textAlign: 'Left',
                                        color: '#346a99',
                                        textShadow: '1px 2px 3px #000000',

                                        height: '100% !important',
                                        background: 'linearGradient(90deg, rgba(214,227,227,1) 0%, rgba(214,227,227,1) 50%, rgba(255,255,255,1) 100%)'

                                    }}  
                                >
                                <h5 style={{textDecorationLine: 'overline'}}>{title}</h5>
                            </div>
                            </Col>
                        </Row>   
                    </div>
                    <div style={{
                            marginTop: 160,
                            paddingTop: '40px',
                            maxWidth: '96%',
                            marginLeft: '2%',
                            marginRight: '2%',
                            textAlign: 'center',

                            backgroundColor: 'whitesmoke'
                             }}>
                        {activeContent}
                        <br/>
                        
                        <br/>
                        <br/>
                    </div>
                    <Col style={{display: 'flex', textAlign: 'center', justifyContent: 'center',position:'fixed',bottom:0,minWidth:'100%'}}>
                            <div 
                                style={{
                                        backgroundColor: '#356a99',
                                        color: 'white',
                                        borderRight: 'solid 1px white',
                                        fontSize: 30,
                                        cursor: 'pointer',
                                        width: '50%'


                                    }} 
                                onClick={this.prevPage.bind(this)}    
                                >
                                {'<<'}
                            </div>
                            <div style={{
                                    backgroundColor: '#356a99',
                                    color: 'white',
                                    fontSize: 30,
                                    cursor: 'pointer',
                                    width: '50%'
                                }} 
                                 onClick={this.nextPage.bind(this)}        
                                 >
                                {'>>'}
                            </div>
                        </Col>
                </div>
                );
    }
}

export default App;
