import React from 'react';
import {Row, Col} from 'react-bootstrap';

class Card extends React.Component {

    render() {
        return (
                <div key={this.props.keyPrefix+"_horizontal_wrapper"} style={{minWidth: '100%', marginTop: 20, backgroundColor: 'whitesmoke'}}>
                    <div key={this.props.keyPrefix+"_horizontal_content"} className="d-none d-md-none d-lg-none d-xl-block" style={{textAlign: 'center', display: 'flex', minWidth: '100%', paddingTop: 10, margin: 10, padding: 10}}>
                        <table key={this.props.keyPrefix+"_horizontal_table"} cellPadding="5px" style={{width: '90%'}}>
                            <tbody>
                                <tr>
                                    <td>
                                        <div  style={{
                                                writingMode: 'tb-rl',
                                                transform: 'rotate(-180deg)',
                                                minWidth: 40,
                                                maxWidth: 40,
                                                boxShadow: '0px 0px 4px #00000066',
                                                textShadow: '0px 0px 6px #00000066',
                                                fontSize: 20,
                                                padding: 5,
                                                height: 300,
                                                backgroundColor:'#95b3c6'

                                                  }}>
                                            {this.props.header}
                                        </div>
                                    </td>
                                    <td style={{verticalAlign: 'top'}}>
                                        <div style={{minHeight: 300, padding: 5, boxShadow: '0px 0px 4px #00000066', textAlign: 'left',borderBottom: 'solid 4px #3a6487',borderTop: 'solid 4px #3a6487'}}>
                                            {this.props.content}
                                        </div>
                                    </td>
                                    <td>
                
                                        <div style={{
                                                display: 'flex',
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                boxShadow: '0px 0px 4px #00000066'
                                                 }}>
                                            {this.props.visualizaiton}
                
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                                
                    <div  
                    key={this.props.keyPrefix+"_vertical_content"} 
                    className="d-block d-md-block d-lg-block d-xl-none" 
                    style={{
                        textAlign: 'center', 
                        display: 'flex', 
                        minWidth: '100%', 
                        paddingTop: 10, 
                        margin: 10, 
                        padding: 10, 
                        backgroundColor: 'whitesmoke'
                    }}>
                        <table key={this.props.keyPrefix+"_vertical_table"} cellPadding="5px"  style={{width: '90%'}}>
                            <tbody>
                                <tr>
                                    <td>
                                        <div  style={{
                                                boxShadow: '0px 0px 4px #00000066',
                                                textShadow: '0px 0px 6px #00000066',
                                                fontSize: 20,
                                                padding: 5,
                                                marginRight: 10,
                                                width: '100%',
                                                backgroundColor:'#95b3c6'
                                                
                                                  }}>
                                            {this.props.header}
                                        </div>
                
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{verticalAlign: 'top'
                                    }}>
                                        <div style={{
                                                minHeight: '100%',
                                                boxShadow: '0px 0px 4px #00000066',
                                                padding: 5,
                                                textAlign: 'left',
                                                borderBottom: 'solid 4px #3a6487',borderTop: 'solid 4px #3a6487'
                                                 }}>{this.props.content}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>  
                                        <div style={{
                                                display: 'flex',
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                boxShadow: '0px 0px 4px #00000066'
                                                 }}>
                                            {this.props.visualizaiton}
                
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>);
    }
}

export default Card;


