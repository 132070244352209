import React from 'react';
import {Container, Nav, Navbar, NavDropdown, Row, Col} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';


class Widget extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (<div style={{display:'flex',justifyContent:'center'}}>
            <div style={{
                            height: '100%',
                            minWidth: 300,
                            maxWidth: 300,
                            borderLeft: 'solid 10px #19cdd7',
                            boxShadow: '0px 2px 3px #000000',
                            backgroundColor: 'rgb(52, 106, 153)',
                            margin: 10
                            
                             }}>
                <div style={{
                                width: '100%',
                                backgroundColor: '#346a99',
                                color: 'white',
                                textAlign: 'center',
                                marginBottom: 5,
                                boxShadow: '0px 2px 3px #000000',
                                padding:5
                                 }}>
                    <img style={{float: 'center', maxHeight: 25, maxWidth: 25}} src={this.props.icon}/>
                </div>
                <div style={{padding: 20, backgroundColor: '#346a99', color: 'white'}}>
                    <p style={{textAlign: 'center',maxHeight:20,marginBottom:10,fontWeight:'bold'}} >{"SEGÍTÜNK!"}</p>
                    <p style={{textAlign: 'center',maxHeight:20,marginBottom:10,fontWeight:'bold',borderBottom:'dashed 1px white'}} ></p>
                    <p style={{textAlign: 'left'}} >{this.props.content}</p>
                </div>
            </div>
        </div>);
    }
}

export default Widget;
