import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <div style={{
                        minHeight: '100%',

                         }}>  
            <div style={{
                    boxShadow: '0px 0px 30px black',
                    marginLeft: '5%',
                    marginRight: '5%',
                    width: '90%',
                    minHeight: '100%',
                    height: '100% !important',
                    textAlign: 'center',
                    backgroundColor: 'white',
                    display: 'contents'
                     }}> <App /> 
            </div> 
        </div>
        );

