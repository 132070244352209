import React from 'react';
import {Container, Nav, Navbar, NavDropdown, Row, Col}
from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import WideCard from './components/WideCard';
import ApacheLogo from './img/ApacheLogo.svg';
import TuxLogo from './img/Tux.svg';
import PHPLogo from './img/PHPLogo.svg';
import DatabaseLogo from './img/Database-mysql.svg';
import ReactLogo from './img/React-icon.svg';

class TechnologiesPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        
        var linuxContent = <div>
            <div>
                <img style={{maxWidth: '200px', maxHeight: '150px'}} src={TuxLogo}/>
            </div>
            <div>
                <br/>
                A világ legnagyobb forgalmú szervereinek 96.3%-a Linux alatt fut.  A világ 500 legerősebb szuperszámítógépének 100%-a Linux operációs rendszert használ.
                <br/>
                <a target="_blank" href="https://www.zdnet.com/home-and-office/networking/can-the-internet-exist-without-linux/" >Link</a>
            </div> 
        </div>;
        var linux = <Col style={{display: 'flex', justifyContent: 'center', verticalAlign: 'top'}} md="6" sm="12" >
        <WideCard key="gnu_linux" keyPrefix="gnu_linux" header="GNU / LINUX" visualizaiton={linuxContent} />
        </Col>;
        var apacheContent = <div>
            <div>
                <img style={{maxWidth: 250, maxHeight: 150}} src={ApacheLogo}/>
            </div>
            <div>
                <br/>
                Az Apache a legszélesebb körben használt webkiszolgálók egyike. 1995 óta van velünk.
                Jelenleg 31.4% a piaci részesedése. Közel 4 millió cég használja világszerte. 
                <br/>
                <a target="_blank" href="https://w3techs.com/technologies/overview/web_server" >Link</a>
        
        
            </div>
        </div>

        var apache = <Col style={{display: 'flex', justifyContent: 'center', verticalAlign: 'top'}} md="6" sm="12" >
        <WideCard 
            key="apache" 
            keyPrefix="apache" 
            header="APACHE KISZOLGÁLÓ"
            visualizaiton={apacheContent} />
        </Col>


        var phpContent = <div>
            <div>
                <img style={{maxWidth: 300, maxHeight: 150}} src={PHPLogo}/>
            </div>
            <div>
                <br/>
                A kezdetektől a webes környezetre lett tervezve, ennek minden előnyével.
                A szerver oldali nyelv 1995-ben jelent meg. 
                A PHP jelenleg a weboldalak 77.4%-án van jelen. 
                <br/>
                <a target="_blank" href="https://w3techs.com/technologies/details/pl-php" >Link</a>
            </div>
        </div>

        var php = <Col style={{display: 'flex', justifyContent: 'center', verticalAlign: 'top'}} md="6" sm="12" >
        <WideCard 
            key="php" 
            keyPrefix="php" 
            header="PHP"
            visualizaiton={phpContent} />
        </Col>

        var mysqlContent = <div>
            <div>
                <img style={{maxWidth: 300, maxHeight: 150}} src={DatabaseLogo}/>
            </div>
            <div>
                A MySQL az egyik legelterjedtebb adatbázis-kezelő a LAMP (Linux–Apache–MySQL–PHP) összeállítás részeként költséghatékony és egyszerűen beállítható megoldást ad dinamikus webhelyek szolgáltatására.
                Szükség esetén lecserélhető a MariaDB adatbázis-kezelővel. 
                <br/>
                <a target="_blank" href="https://www.slintel.com/tech/relational-databases/mysql-market-share" >Link</a>
            </div>
        </div>

        var mysql = <Col style={{display: 'flex', justifyContent: 'center', verticalAlign: 'top'}} md="6" sm="12" >
        <WideCard 
            key="mysql" 
            keyPrefix="mysql" 
            header="MySQL"
            visualizaiton={mysqlContent} />
        </Col>

        var reactContent = <div>
            <div>
                <img style={{maxWidth: 300, maxHeight: 150}} src={ReactLogo}/>
            </div>
            <div>
                <br/>
                Egyike a legnépszerűbb technológiáknak a böngészőben futó alkalmazások létrehozásához. 
                46%-os a piaci részesedése. A világ legnagyobb cégei vesznek részt a fejlesztésében.
                <br/>
                <a target="_blank" href="https://www.statista.com/statistics/1124699/worldwide-developer-survey-most-used-frameworks-web/" >Link</a>
            </div>
        </div>

        var react = <Col style={{display: 'flex', justifyContent: 'center', verticalAlign: 'top'}} md="6" sm="12" >
        <WideCard 
            key="react_js" 
            keyPrefix="react_js" 
            header="ReactJS"
            visualizaiton={reactContent} />
        </Col>;
        var summaryContent = <div style={{height: '100%', marginTop:'8%',textAlign:'left'}}>
                <ul>
                    <li>
                        A szoftvereink fejlesztése során nagy hangsúlyt fektetünk arra, hogy milyen technológiák kerüljenek a rendszerekbe.
                    </li>
                    <li>
                        Nem preferálunk egyetlen olyan technológiát sem, amelyik nem szilárd alapokon nyugszik. 
                    </li>
                    <li>
                        Nem építünk be olyan komponenseket aminek a hosszútávú támogatottsága kétséges.
                    </li>
                    <li>
                        A fejlesztést folyamatosan figyelemmel kísérhetik a megbízóink a külön erre a célra fenntartott szerverünkön. 
                    </li>
                </ul>
        </div>

        var summary = <Col style={{
                             display: 'flex',textAlign:'left'}} md="6" sm="12" >
        <WideCard 
        
            key="alapok" 
            keyPrefix="alapok" 
            header="Alapok"
            visualizaiton=
            {summaryContent}
            />
        </Col>;



        return <Row>       
            {summary}
            {linux}
            {apache}
            {mysql}
            {php}
            {react}
        </Row>
    }
}

export default TechnologiesPage;
